import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeeSelectComponent } from './bee-select/bee-select.component';

import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { BeePipesModule } from '../bee-pipes/bee-pipes.module';
import { BeeLandscapeImageComponent } from './bee-landscape-image/bee-landscape-image.component';
import { BeeAnimatedIconComponent } from './bee-animated-icon/bee-animated-icon.component';
import { CultureSelectComponent } from './culture-select/culture-select.component';
import { BeeInfoComponent } from './bee-info/bee-info.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FileHandlerComponent, FileHandlerDialogComponent } from './file-handler/file-handler.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { HttpClientModule } from '@angular/common/http';
import { BeeModuleHeaderComponent } from './bee-module-header/bee-module-header.component';
import { BeeVideoComponent } from './bee-video/bee-video.component';
import { CourseListComponent } from './modules/academy/course-list/course-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { BeeCulturePickerComponent } from '../frontend/bee-culture-picker/bee-culture-picker.component';
import { JourneyComponent } from './modules/academy/journey/journey.component';
import { BeeRichTextEditorComponent } from './bee-rich-text-editor/bee-rich-text-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';
import { BeeGenericSearchComponent } from './bee-generic-search/bee-generic-search.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { BeeUserNotificationsBellComponent } from './bee-user-notifications-bell/bee-user-notifications-bell.component';
import { BeeUserNotificationsListComponent } from './bee-user-notifications-list/bee-user-notifications-list.component';
import { BeeMyProfileSmallComponent } from './bee-my-profile-small/bee-my-profile-small.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { CourseChapterTableInfoComponent } from './modules/academy/course-chapter-table-info/course-chapter-table-info.component';
import { BeeTableComponent } from './bee-table/bee-table.component';
import { BeeUnitInformationComponent } from './bee-unit-information/bee-unit-information.component';
import { BeeSearchComponent } from './bee-search/bee-search.component';
import { BeeSearchOverlayComponent } from './bee-search-overlay/bee-search-overlay.component';
import { BeePlayIconComponent } from './bee-play-icon/bee-play-icon.component';
import { BeeIconComponent } from './bee-icon/bee-icon.component';
import { BeeBackButtonDirective } from './bee-back-button/bee-back-button.directive';
import { BeeBlogListDefaultComponent } from './bee-blog-list-default/bee-blog-list-default.component';
import { BeeBlogListLegacyComponent } from './bee-blog-list-legacy/bee-blog-list-legacy.component';
import { RouterModule } from '@angular/router';
import { BeeMediaListDefaultComponent } from './bee-media-list-default/bee-media-list-default.component';
import { BeeSocialShareDefaultComponent } from './bee-social-share-default/bee-social-share-default.component';
import { BeeSpeechBubbleComponent } from './bee-speech-bubble/bee-speech-bubble.component';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { BeeLoaderComponent } from './bee-loader/bee-loader.component';
import { BeeClickOutsideDirective } from './bee-click-outside/bee-click-outside.directive';
import { NotificationImageDialogComponent } from './bee-user-notifications-list/dialogs/notification-image-dialog/notification-image-dialog.component';
import { BeeObjectCulturePickerComponent } from './bee-object-culture-picker/bee-object-culture-picker.component';
import { BeeFlagIconComponent } from './bee-flag-icon/bee-flag-icon.component';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
    declarations: [
        BeeSelectComponent,
        BeeLandscapeImageComponent,
        BeeAnimatedIconComponent,
        CultureSelectComponent,
        BeeInfoComponent,
        FileHandlerComponent,
        FileHandlerDialogComponent,
        BeeModuleHeaderComponent,
        BeeVideoComponent,
        CourseListComponent,
        BeeCulturePickerComponent,
        JourneyComponent,
        BeeRichTextEditorComponent,
        BeeGenericSearchComponent,
        BeeUserNotificationsBellComponent,
        BeeUserNotificationsListComponent,
        BeeMyProfileSmallComponent,
        CourseChapterTableInfoComponent,
        BeeTableComponent,
        BeeUnitInformationComponent,
        BeeSearchComponent,
        BeeSearchOverlayComponent,
        BeePlayIconComponent,
        BeeIconComponent,
        BeeBackButtonDirective,
        BeeBlogListDefaultComponent,
        BeeBlogListLegacyComponent,
        BeeMediaListDefaultComponent,
        BeeSocialShareDefaultComponent,
        BeeSpeechBubbleComponent,
        BeeLoaderComponent,
        BeeClickOutsideDirective,
        NotificationImageDialogComponent,
        BeeObjectCulturePickerComponent,
        BeeFlagIconComponent
    ],
    exports: [
        BeeSelectComponent,
        BeeLandscapeImageComponent,
        BeeAnimatedIconComponent,
        CultureSelectComponent,
        BeeInfoComponent,
        FileHandlerComponent,
        BeeModuleHeaderComponent,
        BeeVideoComponent,
        CourseListComponent,
        BeeCulturePickerComponent,
        BeeRichTextEditorComponent,
        BeeGenericSearchComponent,
        BeeUserNotificationsBellComponent,
        BeeUserNotificationsListComponent,
        BeeMyProfileSmallComponent,
        CourseChapterTableInfoComponent,
        BeeUnitInformationComponent,
        BeeTableComponent,
        BeeSearchComponent,
        BeeSearchOverlayComponent,
        BeePlayIconComponent,
        BeeIconComponent,
        BeeBackButtonDirective,
        BeeBlogListDefaultComponent,
        BeeBlogListLegacyComponent,
        BeeMediaListDefaultComponent,
        BeeSocialShareDefaultComponent,
        BeeSpeechBubbleComponent,
        BeeLoaderComponent,
        BeeClickOutsideDirective,
        BeeObjectCulturePickerComponent,
        BeeFlagIconComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        BeePipesModule,
        MatFormFieldModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        MatCardModule,
        MatDialogModule,
        MatSnackBarModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        HttpClientModule,
        NgxFileDropModule,
        MatPaginatorModule,
        MatCheckboxModule,
        CKEditorModule,
        TranslateModule.forChild(),
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BeeComponentsModule { }
