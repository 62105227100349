import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/authorize/services/authentication.service';
import { SiteCulture } from 'src/app/authorize/models/user-session';

@Component({
  selector: 'bee-culture-select',
  templateUrl: './culture-select.component.html',
  styleUrls: ['./culture-select.component.scss']
})
export class CultureSelectComponent implements OnInit {
  @Input() selectedCulture: SiteCulture;

  @Output() onChange = new EventEmitter();

  constructor(public authService: AuthenticationService) { }

  ngOnInit() {
    if(!this.selectedCulture) {
      this.selectedCulture = this.authService.cultures[0];
      this.changeCulture();
    }
  }

  changeCulture(): void {
    this.onChange.emit(this.selectedCulture);
  }

}
