import { Component, OnInit, Input } from '@angular/core';
import { CMSModuleInstance } from '../../cms-models';

@Component({
  selector: 'cms-animation-edit',
  templateUrl: './cms-animation-edit.component.html',
  styleUrls: ['./cms-animation-edit.component.scss']
})
export class CmsAnimationEditComponent implements OnInit {

  @Input() moi: CMSModuleInstance;

  constructor() {
  }

  ngOnInit() {
    console.log(this.moi.settings.scrollAnimation);
  }

  onIsActiveChange(e) {
    this.moi.settings.scrollAnimation.moi = this.moi.instanceId;
  }

  addItem(): void {
    if(!this.moi.settings.scrollAnimation.items) {
      this.moi.settings.scrollAnimation.items = [];
    }

    this.moi.settings.scrollAnimation.items.push({});
  }

  addProperty(item: any): void {
    if(!item.properties) {
      item.properties = [];
    }

    item.properties.push({
      key: '',
      value: ''
    });
  }

  deleteProperty(item: any): void {
    this.moi.settings.scrollAnimation.items.splice(this.moi.settings.scrollAnimation.items.indexOf(item), 1);
  }

}
