<div class="p-4">
  <p>Scroll animations</p>

  <mat-checkbox (change)="onIsActiveChange($event)" [(ngModel)]="moi.settings.scrollAnimation.isActive" color="primary" name="scrollAnimIsActive">Is active</mat-checkbox>

  <mat-form-field>
    <input matInput placeholder="Trigger" name="trigger" [(ngModel)]="moi.settings.scrollAnimation.trigger">
  </mat-form-field>

  <div class="mt-4" *ngIf="moi.settings.scrollAnimation.isActive">

    <div class="mt-2 mb-2">
      <div class="bee-button" (click)="addItem()">
        Add item
      </div>
    </div>

    <div class="mt-4" *ngFor="let item of moi.settings.scrollAnimation.items; let i = index">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>{{item.name}}</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
    
        <div class="mt-4">
          <mat-form-field>
            <input matInput placeholder="Module name to affect" name="moduleNameToAffect" [(ngModel)]="item.name">
          </mat-form-field>

          <div class="bee-button mt-4" (click)="addProperty(item)">Add property</div>

          <mat-icon (click)="deleteProperty(item)">delete</mat-icon>
        </div>

        <div class="mt-4" *ngFor="let prop of item.properties; let y = index">
          <mat-form-field>
            <input matInput placeholder="Key" name="moduleKey-{{y}}" [(ngModel)]="prop.key">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Value" name="moduleValue-{{y}}" [(ngModel)]="prop.value">
          </mat-form-field>
        </div>
    
      </mat-expansion-panel>
    </div>

  </div>
</div>