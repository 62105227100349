<div class="beebase-editor">
  <div class="editor-canvas">
      <div class="inner" [ngClass]="deviceEmulation">
        <div *ngFor="let moduleInstance of container.moduleInstances | sort:'order' | filter:['isDeleted', false]" id="{{moduleInstance.instanceId}}" class="module-instance"
          [ngClass]="{'selected': (selectedModuleInstance && moduleInstance.instanceId == selectedModuleInstance.instanceId)}"
          [ngStyle]="moduleInstance.settings.style | mediaQuery:{'mobile': moduleInstance.settings.mobileStyle, 'deviceEmulation': deviceEmulation }"
          [class.videoBackground]="deviceEmulation == 'desktop' ? moduleInstance.settings.style.backgroundVideo : moduleInstance.settings.mobileStyle.backgroundVideo"
          [style.background-image]="'url(' + (moduleInstance.settings.style?.backgroundImage?.imgUrl || moduleInstance.settings.style.backgroundImage || '' | cdn) + ')'"
          (click)="setSelectedMoi(moduleInstance)">

            <video *ngIf="moduleInstance.settings.style.backgroundVideo" class="moi-bgVideo" autoplay [muted]="'muted'" loop id="bgVideo-{{moduleInstance.instanceId}}">
              <source [src]="moduleInstance.settings.style.backgroundVideo | cdn" type="video/mp4">
            </video>

            <app-cms-module-wrapper [moi]="moduleInstance" [isAdmin]="true" [hasAdminAccess]="isAdmin()"></app-cms-module-wrapper>

            <div style="position: absolute; top: 5px; right: 5px;" *ngIf="isAdmin()">
              <i class="material-icons sm" (click)="moveUp(moduleInstance); $event.stopPropagation();" matTooltip="Move module up">arrow_upward</i>
              <i class="material-icons sm margin-right" (click)="moveDown(moduleInstance); $event.stopPropagation();" matTooltip="Move module down">arrow_downward</i>

              <i class="material-icons sm" (click)="saveCopy(moduleInstance); $event.stopPropagation();" matTooltip="Save a copy of this module to paste later">save_alt</i>
              <i class="material-icons sm" (click)="copyMoi(moduleInstance); $event.stopPropagation();" matTooltip="Copy module">file_copy</i>
              <i class="material-icons sm" (click)="createModuleInstanceTemplate(moduleInstance); $event.stopPropagation();">save</i>
              <i class="material-icons sm" (click)="deleteMoi(moduleInstance)" matTooltip="Delete module">delete</i>
              <!-- <i class="material-icons sm drag" cdkDragHandle>drag_handle</i> -->
            </div>
        </div>
      </div>
  </div>

  <div class="editor-settings">

    <div class="settings-actions">
      <mat-button-toggle-group name="device" aria-label="Device emulation" (change)="selectDeviceEmulation($event)">
        <mat-button-toggle value="desktop" [checked]="deviceEmulation == 'desktop'">
          Desktop
          <mat-icon>computer</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="mobile" [checked]="deviceEmulation == 'mobile'">
          Mobile
          <mat-icon>smartphone</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>

    </div>

    <div *ngIf="!selectedModuleInstance">
      <div class="header center">Modules</div>

      <div class="margin-top margin-bottom margin-left admin-buttons" *ngIf="isAdmin()">

        <div class="icon-wrapper">
          <mat-icon matTooltip="Get copied page content" *ngIf="hasCopiedContainer" (click)="showJsonDialog('COPY_CMS_CONTAINER')">copy_all</mat-icon>
          <mat-icon matTooltip="Get copied module content" *ngIf="hasCopiedMoi" (click)="showJsonDialog('COPY_CMS_MOI')">content_copy</mat-icon>

          <mat-icon matTooltip="Paste page content" (click)="pasteJsonDialog('COPY_CMS_CONTAINER')">web</mat-icon>
          <mat-icon matTooltip="Paste module content" (click)="pasteJsonDialog('COPY_CMS_MOI')">layers</mat-icon>
        </div>

        <div class="margin-bottom">
          <button (click)="addModule()" class="margin-right" color="primary" mat-raised-button>Add module</button>
        </div>

        <div class="margin-bottom">
          <button (click)="copyContainer()" mat-stroked-button>Copy entire page</button>
          <button (click)="addCopiedContainer()" mat-stroked-button *ngIf="hasCopiedContainer">Add copied page</button>
        </div>
        <button (click)="addCopiedModule()" mat-stroked-button *ngIf="hasCopiedMoi">Add copied module</button>

        <div class="mt-4">
          <button (click)="animations()" mat-stroked-button>
            <mat-icon>animation</mat-icon>
            Animations
          </button>
        </div>
      </div>

      <!-- <div cdkDropList [cdkDropListData]="moduleAbstracts" [cdkDropListConnectedTo]="[instancesList]">
        <mat-card *ngFor="let moduleAbstract of moduleAbstracts" cdkDrag>
          <i class="material-icons large md-inactive">{{moduleAbstract.icon}}</i>
          <p>{{moduleAbstract.abstractNname}}</p>
        </mat-card>
      </div> -->


      <h2 style="padding-left: 15px; margin-top: 30px;">Module instances:</h2>
      <div class="margin-top module-instances-wrapper" cdkDropList (cdkDropListDropped)="dropModuleInstance($event)">
        <mat-card *ngFor="let moi of container.moduleInstances | sort:'order' | filter:['isDeleted', false]" [cdkDragDisabled]="!isAdmin()" cdkDrag cdkDragBoundary=".module-instances-wrapper"
          class="margin-top sm container-moi-dnd"
          (click)="setSelectedMoi(moi)">
          <div style="display: flex; align-items: center;">
            <i class="material-icons large md-inactive margin-right sm">{{moi.icon}}</i>
            <span>{{moi.abstractName}} {{getName(moi)}}</span>
          </div>

          <div style="display: flex; align-items: center;" *ngIf="isAdmin()">
            <i class="material-icons sm" (click)="saveCopy(moi); $event.stopPropagation();">save_alt</i>
            <i class="material-icons sm" (click)="copyMoi(moi); $event.stopPropagation();">file_copy</i>
            <i class="material-icons sm" (click)="deleteMoi(moi); $event.stopPropagation();">delete</i>
            <i class="material-icons large md-inactive margin-right sm drag" cdkDragHandle>drag_handle</i>
          </div>
        </mat-card>
      </div>
    </div>

    <div *ngIf="selectedModuleInstance">
      <div class="header">
        <span>Module settings</span>
        <div style="display: flex;">
          <div *ngIf="selectedModuleInstance.parentId" (click)="setSelectedMoiById(selectedModuleInstance.parentId, $event)">
            <i class="material-icons">arrow_back</i>
          </div>
          <div (click)="setSelectedMoi(undefined)">
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>

      <div class="padding">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Module settings
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-form-field *ngIf="isAdmin()">
            <input matInput placeholder="Name" name="name" [(ngModel)]="selectedModuleInstance.name">
          </mat-form-field>
          <app-cms-module-settings-wrapper [moi]="selectedModuleInstance" [hasAdminAccess]="isAdmin()"></app-cms-module-settings-wrapper>

          <div class="margin-top" *ngIf="!isAdmin()">

            <div class="content-item" *ngIf="selectedModuleInstance.settings.style.backgroundImage">
              <div>Background image</div>
              <mediabank-file-handler [(mediaFile)]="selectedModuleInstance.settings.style.backgroundImage" (callback)="setMobileBackgroundImage($event)" [fileType]="1" returnType="string"></mediabank-file-handler>
            </div>
        
            <div class="content-item margin-top" *ngIf="selectedModuleInstance.settings.style.backgroundVideo">
              <div>Background video</div>
              <mediabank-file-handler [(mediaFile)]="selectedModuleInstance.settings.style.backgroundVideo" (callback)="setMobileBackgroundVideo($event)" [fileType]="2" returnType="string"></mediabank-file-handler>
            </div>

            <div class="content-item">
              <div>Show / hide module</div>
              <mat-button-toggle-group (change)="toggleShowHide($event)" [value]="getShowHideValue()">
                <mat-button-toggle value="show" aria-label="Show">
                  Show
                </mat-button-toggle>
                <mat-button-toggle value="hide" aria-label="Hide">
                  Hide
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

          </div>

          <div class="margin-top">
            <h5>Click Events</h5>
            <mat-form-field ngDefaultControl>
              <mat-select [(ngModel)]="selectedModuleInstance.settings.content.scrollOption">
                <mat-option *ngFor="let eventChoiceOption of eventChoiceOptions" [value]="eventChoiceOption.value">
                  {{eventChoiceOption.key}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="selectedModuleInstance.settings.content.scrollOption == 'link'">
              <input matInput placeholder="Link URL" name="link" [(ngModel)]="selectedModuleInstance.link">
            </mat-form-field>

            <div class="margin-top" *ngIf="selectedModuleInstance.settings.content.scrollOption == 'link'">
              <mat-checkbox color="primary" [(ngModel)]="selectedModuleInstance.settings.content.linkOpenNewWindow">Open link in new window</mat-checkbox>
            </div>
          </div>

            <mat-form-field *ngIf="isAdmin()">
              <input matInput placeholder="Css class" name="cssClass" [(ngModel)]="selectedModuleInstance.settings.cssClass">
            </mat-form-field>

          <div class="margin-top">
            <mat-checkbox color="primary" [(ngModel)]="selectedModuleInstance.showDialog">Open dialog on click</mat-checkbox>
          </div>

          <div class="margin-top" *ngIf="selectedModuleInstance.showDialog">

            <div class="content-item">
              <div>Type: </div>
              <mat-select placeholder="Dialog type" [(ngModel)]="selectedModuleInstance.dialogData.type" name="dialogType">
                <mat-option value="">Standard</mat-option>
                <mat-option value="container">CMS Page</mat-option>
              </mat-select>
            </div>

            <div class="margin-top" *ngIf="!selectedModuleInstance.dialogData.type || selectedModuleInstance.dialogData.type == ''">
              <div>
                <span>Icon</span>
                <mediabank-file-handler [(mediaFile)]="selectedModuleInstance.dialogData.icon" returnType="string"></mediabank-file-handler>
              </div>

              <div class="margin-top">
                <mat-form-field>
                  <input matInput placeholder="Header" name="header" [(ngModel)]="selectedModuleInstance.dialogData.header">
                </mat-form-field>
              </div>

              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Right side content
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div>
                  <mat-form-field>
                    <input matInput placeholder="Right side header" name="rightHeader" [(ngModel)]="selectedModuleInstance.dialogData.rightHeader">
                  </mat-form-field>
                </div>

                <div class="margin-top">
                  <span>Right side text</span>
                  <ckeditor [editor]="Editor" [(ngModel)]="selectedModuleInstance.dialogData.rightText" [config]="editorConfig"></ckeditor>
                </div>
              </mat-expansion-panel>

              <div class="margin-top">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Left side content
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div>
                    <mat-form-field>
                      <input matInput placeholder="Left side header" name="leftHeader" [(ngModel)]="selectedModuleInstance.dialogData.leftHeader">
                    </mat-form-field>
                  </div>

                  <div class="margin-top">
                    <span>Left side text</span>
                    <ckeditor [editor]="Editor" [(ngModel)]="selectedModuleInstance.dialogData.leftText" [config]="editorConfig"></ckeditor>
                  </div>
                </mat-expansion-panel>
              </div>
            </div>

            <div class="margin-top" *ngIf="selectedModuleInstance.dialogData.type == 'container'">

              <mat-select placeholder="Dialog to be shown" [(ngModel)]="selectedModuleInstance.dialogData.dialogId" name="dialogId">
                <mat-option *ngFor="let dialog of dialogs" [value]="dialog.id">
                  {{dialog.name}}
                </mat-option>
                <mat-option *ngIf="dialogs.length <= 0">-- Det finns inga dialoger --</mat-option>
              </mat-select>

            </div>

          </div>
        </mat-expansion-panel>

        <mat-tab-group *ngIf="isAdmin()">
          <mat-tab label="Desktop style">
            <div class="margin-bottom">
              <cms-style-edit [style]="selectedModuleInstance.settings.style"></cms-style-edit>
            </div>
          </mat-tab>
          <mat-tab label="Mobile style">
            <cms-style-edit [style]="selectedModuleInstance.settings.mobileStyle"></cms-style-edit>
          </mat-tab>
          <mat-tab label="Animations">
            <cms-animation-edit [moi]="selectedModuleInstance"></cms-animation-edit>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

</div>
