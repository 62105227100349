import { Component, OnInit, Input, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { CMSContainer, CMSModuleInstance } from '../cms-models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'container-cms-view',
  templateUrl: './cms-view.component.html',
  styleUrls: ['./cms-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsViewComponent implements OnInit {
  public container$: BehaviorSubject<CMSContainer> = new BehaviorSubject<CMSContainer>(null);
  private _container: CMSContainer;
  
  get container(): CMSContainer {
    return this._container;
  }
  
  @Input() set container(value: CMSContainer) {
    this._container = value;
    this.container$.next(value);
  };

  constructor(public dialog: MatDialog, public elementRef: ElementRef) {
  }

  ngOnInit() {
  }

  getExtraClass(moi: CMSModuleInstance) {
    var extraClass: string = "";
    if(moi.settings.animationData && Object.keys(moi.settings.animationData).length > 0) {
      extraClass += " overflow_hidden"
    }

    return extraClass;
  }

  ngAfterViewChecked() {
    const dom = this.elementRef.nativeElement;
    const elements = dom.querySelectorAll('.moi-bgVideo');
    
    if(elements.length > 0) {
      elements.forEach(element => {
        if(element.onloadeddata == null) element.onloadeddata = this.videoLoaded;
      });
    }
  }

  videoLoaded(event) {
    const width = event.srcElement.offsetWidth;

    const parent = event.srcElement.parentElement;
    const parentWidth = parent.offsetWidth;

    const widthDiff = parentWidth - width;

    //set left
    event.srcElement.style.left = (widthDiff / 2) + "px";
    event.srcElement.style.opacity = "1";
  }

}
